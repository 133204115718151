






















































































.q-list
  position: absolute
  background-color: white
  overflow: auto;
  bottom: 0
  top: 130px
  left: 5%
  width: 90%;
  z-index: 10000
  height: 60vh
  display flex
  flex-direction column
  box-shadow 0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0 0 0 0.14), 0 3px 1px -2px rgba(0 0 0 0.12)

.close
  position: sticky
  width: 100%

.key
  font-size 125%
